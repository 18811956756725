// Page IndividualProject
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Import Data
import projectsData from '../data/project-data';
import { appTitle } from '../globals/globals';
// Import Components
import Button from '../components/Button';
import AnimatedPages from '../components/AnimatedPages';
// Import SVGs
import { ReactComponent as ToolKit } from '../images/svg/toolkit.svg';
import { ReactComponent as Skills } from '../images/svg/skill.svg';

const imageFolderPath = process.env.PUBLIC_URL + '/assets/images/';

const PageIndividualProject = () => {

    // Set the Individual Project page title
    useEffect(() => {
        document.title = `${appTitle} | Project Details`;
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    // Get the project link from the URL
    const { id } = useParams();

    // Find the project object that matches the link
    const projectObj = projectsData.find(project => project.link === id);

    useEffect(() => {

        if (!projectObj) {
            document.title = `${appTitle} - Project Not Found`;
        } else {
            document.title = `${appTitle} - Project Details: ${projectObj.title}`;
        }

    }, [projectObj]);

    return (
        <AnimatedPages>
        <main className="page individual-project-page">
            <h1>{projectObj.title}</h1>
            <div className="individual-project-wrapper">
                <div className="project-hero">
                    <div className="project-mockup">
                        <img src={`${imageFolderPath}${projectObj.mockupimg}`} alt={`A mockup of the ${projectObj.title}`} loading="lazy"/>
                    </div>

                    <p>{projectObj.overview}</p>

                    <Button link={projectObj.livesite} isPDF={projectObj.isPDF} />
                </div>

                <div className="project-development">
                    <h2>Development</h2>
                    <p>{projectObj.planning}</p>
                    <p>{projectObj.development}</p>
                </div>

                <div className="project-technologies">
                    <div className='project-skill-card project-skills'>
                        <div className='icon-container'>
                            < Skills />
                        </div>
                        <h2>Skills Used</h2>
                        <ul>
                            {projectObj.skills.map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))}
                        </ul>
                    </div>

                    <div className='project-skill-card project-toolkit'>
                        <div className='icon-container'>
                            < ToolKit />
                        </div>
                        <h2>Toolkit</h2>
                        <ul>
                            {projectObj.toolkit.map((tool, index) => (
                                <li key={index}>{tool}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className='project-reflection'>
                    <h2>Reflection</h2>
                    <p>{projectObj.reflection}</p>
                </div>
            </div>
        </main>
        </AnimatedPages>
    );
}

export default PageIndividualProject;