// Main Navigation
import { NavLink } from 'react-router-dom';

const Nav = ({ handleShowHideNav }) => {

    // Close the nav if the user clicks on a link
    function closeNav(e) {
        if (window.innerWidth < 800) {
            handleShowHideNav();
        } else {
            e.target.blur();
        }
    }

    return (
        <nav className="main-nav" onClick={closeNav}>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/projects">My Work</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
            </ul>
        </nav>
    );

};

export default Nav;
