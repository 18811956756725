// AppRouter

// Development Components
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';

// Pages
import PageHome from '../pages/PageHome';
import PageAbout from '../pages/PageAbout';
import PageProjects from '../pages/PageProjects';
import PageIndividualProject from '../pages/PageIndividualProject';

function AppRouter() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/projects" element={<PageProjects />} />
          <Route path="/about" element={<PageAbout />} />
          <Route path="/project/:id" element={<PageIndividualProject />} />
          <Route path="*" element={<PageHome />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default AppRouter;