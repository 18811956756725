// Header
import { useState, useEffect } from 'react';
// Import Components
import Nav from './Nav';
import SocialNav from './SocialNav';

const Header = () => {

    const [navOpen, setNavOpen] = useState(false);

    // Handle main navigation
    const showHideNav = () => {
        setNavOpen(!navOpen);
    }

    // Close the nav if the user resizes the window to desktop size
    const isDesktop = (e) => {
        if (e.matches) {
            setNavOpen(false);
        }
    }

    useEffect(() => {
        let mediaQuery = window.matchMedia('(min-width: 50em)');
        mediaQuery.addEventListener('change', isDesktop);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeEventListener('change', isDesktop);
    }, []);

    return (
        <header className={navOpen ? 'show' : undefined}>

            <button className="btn-main-nav"
                onMouseDown={(e) => { e.preventDefault(); }}
                onClick={showHideNav}>
                <span className="hamburger-icon">
                    <span className="line"></span>
                    <span className="line"></span>
                </span>
                <span className="sr-only">Menu</span>
            </button>

            <Nav handleShowHideNav={showHideNav} />

            <SocialNav />

        </header>
    );

};

export default Header;