// Footer
import { getYear } from '../utilities/getDates';
import { NavLink } from 'react-router-dom';
// Import Components
import SocialNav from './SocialNav';

const Footer = ({ copyright, author }) => (
  <footer>
    <nav className="footer-nav">
      <ul>
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/projects">My Work</NavLink></li>
        <li><NavLink to="/about">About</NavLink></li>
      </ul>
    </nav>
    <SocialNav />
    <p>&copy; {author} {copyright} </p>
  </footer>
);

Footer.defaultProps = {
  author: 'Caitlyn Wen',
  copyright: getYear()
}

export default Footer;