import { useEffect, useRef, useState } from "react";

export function useTypingEffect(text, keyDuration) {

  // Set current position to 0
  const [currentPosition, setCurrentPosition] = useState(0);
  // Use ref to keep track of current position
  const currentPositionRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {

      // console.log("interval");

      setCurrentPosition((value) => value + 1);
      currentPositionRef.current += 1;

      // If current position is greater than text length, clear interval
      if (currentPositionRef.current > text.length) {
        clearInterval(intervalId);
      }

    }, keyDuration);

    return () => {
      // Clear interval
      clearInterval(intervalId);
      currentPositionRef.current = 0;
      setCurrentPosition(0);
    };

  }, [keyDuration, text]);

  return text.substring(0, currentPosition);
}
