// Porject component
import { Link } from 'react-router-dom';

// Get the path for images
const imageFolderPath = process.env.PUBLIC_URL + '/assets/images/';

function Projects({ projectObj, projectLink }) {

  return (
    <div className="project-item">

      <div className="project-item-img">
        <img src={`${imageFolderPath}${projectObj.cover}`} alt={projectObj.title} loading="lazy"/>
      </div>

      <div className="project-item-text">
        <h3>{projectObj.title}</h3>
        <p>{projectObj.description}</p>
        {projectLink && <div >

          <Link to={{ pathname: `/project/${projectObj.link}`, state: { projectObj } }} className="link">
            <span className="mask">
              <div className="link-container">
                <span className="link-title1 title">View Project</span>
                <span className="link-title2 title">View Project</span>
              </div>
            </span>
            <div className="link-icon">
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m11.707 3.293-1.414 1.414L17.586 12l-7.293 7.293 1.414 1.414L20.414 12l-8.707-8.707z" /><path d="M5.707 3.293 4.293 4.707 11.586 12l-7.293 7.293 1.414 1.414L14.414 12 5.707 3.293z" /></svg>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m11.707 3.293-1.414 1.414L17.586 12l-7.293 7.293 1.414 1.414L20.414 12l-8.707-8.707z" /><path d="M5.707 3.293 4.293 4.707 11.586 12l-7.293 7.293 1.414 1.414L14.414 12 5.707 3.293z" /></svg>
            </div>
          </Link>


        </div>}
      </div>
    </div>
  );
}

export default Projects;