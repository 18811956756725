// Page Home
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// Import Data
import projectsData from '../data/project-data';
import { appTitle } from '../globals/globals';
// Import images and icons
import { ReactComponent as Arrow } from '../images/svg/arrow.svg';
import laptop from '../images/laptop.png';
// Import Components
import Projects from '../components/Projects';
import AvatarCard from '../components/AvatarCard';
import AnimatedPages from '../components/AnimatedPages';
// Import Hooks
import { useTypingEffect } from '../hooks/typing-animation';

function PageHome() {
    // Set the home page title
    useEffect(() => {
        document.title = `${appTitle} | Home`;
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    // Extract the first four projects as featured projects
    const featuredProjects = projectsData.slice(0, 4);

    // Scroll to the about section
    const handleClickScroll = () => {
        const element = document.getElementById('home-about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Typing effect
    const animationh1 = useTypingEffect("Hi, I'm Caitlyn", 100);

    return (
        <AnimatedPages>
            <main className="page home-page">

                <section className="home-section">
                    <div className="home-intro">
                        <div className='home-intro-text'>
                            <h1>{animationh1}</h1>
                            <p>Font-End Web Developer <br />based in Vancouver</p>
                            <p>I like to code and design both aesthetically pleasing and technically sound.</p>
                        </div>
                    </div>

                    <div className="home-laptop">
                        <img src={laptop} alt="Desktop with plants and pc showing webpages" loading="lazy"/>
                    </div>

                    <div className="home-arrow">
                        <button className="btn-scroll" onClick={handleClickScroll}>
                            <Arrow />
                        </button>
                    </div>
                </section>

                <section className="home-about" id="home-about">
                    <div className='home-about-text'>
                        <h2>Hi,</h2>
                        <p>
                            My name is Caitlyn, I am a Front End Developer and Designer graduating from BCIT's <Link to="https://www.bcit.ca/programs/front-end-web-developer-certificate-full-time-6535cert/?gclid=CjwKCAjw5MOlBhBTEiwAAJ8e1v_sUHth0bIq8ecPfRzjUC58NlmrthnHgCL9uuF4YYihOT_vMkPuLxoCNoEQAvD_BwE&gclsrc=aw.ds">FWD program.
                            </Link>
                        </p>

                        <p>
                            My toolkits include but are not limited to  HTML, CSS, JavaScript, PHP, React, WordPress, Adobe XD, Figma, etc.
                        </p>
                    </div>
                    <div className='home-avatar'>
                        <Link to="/about">
                            <AvatarCard />
                        </Link>
                    </div>
                </section>

                <section className="home-projects">
                    <h2>Featured Projects</h2>
                    <div className="project-card">
                        {featuredProjects.map((project, i) => {
                            return <Projects key={i}
                                projectObj={project}
                                projectLink={true} />
                        })}
                    </div>
                </section>

            </main>
        </AnimatedPages>
    );
}

export default PageHome;