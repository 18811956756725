// Page About
import { useEffect } from 'react';
import { appTitle } from '../globals/globals';
// Import Components
import AvatarCard from '../components/AvatarCard';
import AnimatedPages from '../components/AnimatedPages';
import { useTypingEffect } from '../hooks/typing-animation';

const PageAbout = () => {

    // Set the about page title
    useEffect(() => {
        document.title = `${appTitle} | About`;
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    // Typing effect
    const animationgreeting = useTypingEffect("Hello, there!", 100);

    return (
        <AnimatedPages>
        <main className="page about-page">
            <h1>About Me</h1>
            <section className="about-section">
                <div className="about-info">
                    <h2> {animationgreeting} </h2>
                    <p>Thanks for getting here!</p>
                    <p>Currently, I am studying Front-End Web Development at BCIT to pursue my passion for technology and design. </p>
                </div>

                <AvatarCard />

                <div className="about-info">
                    <p>Aside from coding and designing websites, my other interests include reading, playing video games, and listening to music. </p>
                </div>
            </section>

            <section className="contact-section">
                <h2>Contact Me</h2>
                <div className="contact-info">
                    <p>Feel free to reach out if you're interested in working together, discussing my projects, being friends, or playing Splatoon 3 together! </p>

                    <p>Please fill out the form below or email me at:
                        <a href="mailto:cwen14@my.bcit.ca"> cwen14@my.bcit.ca</a>
                    </p>
                </div>
            </section>

        </main>
        </AnimatedPages>
    );
}

export default PageAbout;