// Avatar card component for the About page
// Import avatar images
import avatar from '../images/avatar.png';
import avatarFlip from '../images/avatar-flip.png';

function AvatarCard() {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={avatar} alt="Avatar of Caitlyn" loading="lazy"/>
        </div>
        <div className="flip-card-back">
          <img src={avatarFlip} alt="Flip avatar of Caitlyn" loading="lazy"/>
        </div>
      </div>
    </div>
  )
}

export default AvatarCard