// Page Projects
import { useEffect } from 'react';
import { appTitle } from '../globals/globals';
// Import Data
import projectsData from '../data/project-data';
// Import Components
import Projects from '../components/Projects';
import AnimatedPages from '../components/AnimatedPages';

const PageProjects = () => {

    // Set the Projects page title
    useEffect(() => {
        document.title = `${appTitle} | Projects`;
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <AnimatedPages>
        <main className="page projects-page">
            <h1>My Work</h1>
            <div className="projects-section">
                <div className="project-card">
                    {projectsData.map((project, i) => {
                        return <Projects key={i}
                            projectObj={project}
                            projectLink={true} />
                    })}
                </div>
            </div>
        </main>
        </AnimatedPages>
    );
}

export default PageProjects;