// Buttons for the individual project page
import { Link } from 'react-router-dom';

function Button({ link, isPDF }) {

  // Get the path for PDF files
  const pdfUrl = process.env.PUBLIC_URL + '/assets/pdf/';

  // If the link is a PDF
  if (isPDF) {
    return (
      <button className="livesite-btn">
        <Link to={`${pdfUrl}${link}`} target="_blank" rel="noopener noreferrer">
          View PDF
        </Link>
      </button>
    );
  }

  // Otherwise default to a regular link
  return (
    <button className="livesite-btn">
      <Link to={link} target="_blank" rel="noopener noreferrer">
        Live Site
      </Link>
    </button>
  );
  
}

export default Button